<template>
<div class="start">
  <img class="img" loading="lazy" :src="require('@/assets/img/start.jpg')" alt="" width="100" height="372" />

  <div class="description">
    <p>Diese Befragung zum Thema Change Readiness fokussiert auf die Handlungsfelder Strukturen, Prozesse, Führungs- und Unternehmenskultur sowie Mitarbeiter und Qualifikationen.</p>
    <p>Ziel ist es herauszufinden, wie es um das Veränderungsvermögen einzelner Unternehmen bestellt ist.</p>
    <p>Die Antworten erlauben Rückschlüsse darüber, wie weit die Organisation in der Lage ist, sich an eine dynamische Umwelt anzupassen. Basierend auf den Ergebnissen des Change Readiness Checks werden individuelle Handlungsempfehlungen abgeleitet.</p>
    <p>Die Befragung dauert ca. 20 Minuten.</p>
  </div>

  <div class="position input-field" v-if="requiresPosition">
    <label class="label" for="position-select">Bitte wählen Sie ihre Position bei <strong v-text="clientName"/>:</label>
    <select class="input -select" id="position-select" @change="updatePosition($event.target.value)">
      <option selected="selected" disabled value="">--- Bitte Position wählen ---</option>
      <option
        v-for="(text, key) in positions"
        :key="key"
        :value="key"
        v-text="text"
      />
    </select>
  </div>

  <button type="button" class="button -primary" @click.prevent.stop="next" :disabled="!isReady">Starten</button>
</div>
</template>

<script>
import IsStep from "@/mixins/IsStep";
import {mapState} from "vuex";

export default {
  name: 'Start',
  mixins: [IsStep],

  computed: {
    isReady() {
      return this.$store.getters['startCheck']();
    },

    ...mapState({
      clientName: state => state.clientName,
      requiresPosition: state => state.requiresPosition,
      positions: state => state.positions,
    }),
  },

  methods: {
    updatePosition(position) {
      this.$store.commit('setPosition', position);
    },
  },
}
</script>
