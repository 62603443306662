import Vue from 'vue';
import Vuex from 'vuex';

const language = document.documentElement.lang || 'de';

Vue.use(Vuex);

function resultTextKey(sum) {
    if(typeof sum !== 'number' || sum <= 9) {
        return 'review_construct';
    }

    if(sum <= 15) {
        return 'review_develop';
    }

    return 'develop_grow';
}

function sendJsonRequest(uri, data, method = 'GET') {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(method, `${process.env.VUE_APP_API_URI}${uri}`, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('Accept-Language', language);

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(JSON.parse(xhr.response));
            } else {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText,
                    response: JSON.parse(xhr.response),
                });
            }
        };

        xhr.onerror = () => {
            reject({
                status: xhr.status,
                statusText: xhr.statusText,
                response: JSON.parse(xhr.response),
            });
        };

        xhr.send(JSON.stringify(data));
    });
}

const actions = {
    async init({state, dispatch, commit}) {
        const loadTranslations = dispatch('loadTranslations');
        const client = dispatch('client');

        await client;
        await loadTranslations;

        if (Object.keys(state.relevance).length > 0 && Object.keys(state.actualStates).length > 0) {
            commit('canStart');
        }
    },

    async mail({state}, data) {
        const body = Object.assign(data, {
            result: getters.resultResponse(state)(),
        });

        return await sendJsonRequest('mail', body, 'POST');
    },

    async save({state}) {
        const hash = state.hash ? state.hash : null;
        const position = state.selectedPosition ? state.selectedPosition : null;
        if (!hash || !position) {
            return;
        }

        const body = {
            hash,
            position,
            result: getters.resultResponse(state)(),
        };

        try {
            await sendJsonRequest('save', body, 'POST');
        } catch (e) {
            console.error(e);
        }
    },

    async client({commit}) {
        if (!window.location.hash) {
            return Promise.resolve();
        }

        const hash = window.location.hash.replace('#', '');
        if(!hash || typeof hash !== 'string' || hash.length !== 6) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            sendJsonRequest(`${hash}/name`)
                .then((response) => {
                    commit('setClient', {hash, name: response.name});
                    resolve();
                })
                .catch(() => reject());
        });

    },

    async loadTranslations({commit}) {
        return new Promise((resolve, reject) => {
            sendJsonRequest(`lang`)
                .then((translation) => {
                    commit('translate', translation);
                    resolve();
                })
                .catch(() => reject());
        });
    }
};

const getters = {
    startCheck: state => () => state.ready && ((state.requiresPosition === true && state.selectedPosition) || state.requiresPosition === false),

    actualStateKeys: state => () => Object.keys(state.actualStates),

    canContinueRelevance: state => () => {
        let can = true;
        Object.keys(state.results).forEach(key => {
            if (state.results[key].relevance === 0) {
                can = false;
            }
        });

        return can;
    },

    resultResponse: state => () => {
        const result = {};

        Object.keys(state.results).forEach(key => {
            const {actualState, relevance, questions} = state.results[key];

            result[key] = {
                state: actualState,
                relevance,
                questions,
                text: resultTextKey(actualState),
            }
        })

        return result;
    }
};

const mutations = {
    canStart(state) {
        state.ready = true;
    },

    setClient(state, {name, hash}) {
        state.hash = hash;
        state.clientName = name;
        state.requiresPosition = true;
    },

    setPosition(state, position) {
        state.selectedPosition = position ? position : null;
    },

    changeLinkDataProtection(state, link) {
        if(typeof link === 'string') {
            state.link.dataProtection = link;
        }
    },

    relevanceResult(state, {key, value}) {
        if (key && value && state.results[key]) {
            state.results[key].relevance = value;
        }
    },

    actualStateQuestionResult(state, {context, index, value}) {
        if (context && value && state.results[context]) {
            state.results[context].questions[index] = value;

            let sum = 0;
            let can = true;
            state.results[context].questions.forEach((value) => {
                sum += value;
                if (value === 0) {
                    can = false;
                }
            });
            state.results[context].actualState = sum;
            state.results[context].text = state.resultTexts[context][resultTextKey(sum)];
            state.canContinue[context] = can;
        }
    },

    translate(state, translation) {
        // store positions translations
        Vue.set(state, 'positions', translation.positions);

        // store check translations
        Object.keys(translation.check).forEach((key) => {
            const {relevance, title, questions, results, label = []} = translation.check[key];

            Vue.set(state.relevance, key, {title, text: relevance});
            Vue.set(state.canContinue, key, false);
            Vue.set(state.actualStates, key, {title, questions});
            Vue.set(state.resultTexts, key, results);
            Vue.set(state.results, key, {
                title,
                label: label && label.length > 0 ? label : title,
                relevance: 1,
                actualState: 0,
                questions: Array(questions.length).fill(0),
                text: null,
            });
        });
    }
};

export default new Vuex.Store({
    state: {
        link: {
            dataProtection: '#',
        },
        hash: null,
        clientName: null,
        ready: false,
        selectedPosition: null,
        requiresPosition: false,
        positions: {},
        relevance: {},
        actualStates: {},
        results: {},
        resultTexts: {},
        canContinue: {},
    },
    actions,
    getters,
    mutations,
});
