import Vue from 'vue';

export default class Validations {
  constructor() {
    this.validations = {};
  }

  has(field) {
    return Object.prototype.hasOwnProperty.call(this.validations, field);
  }

  hasAny(field) {
      const regex = new RegExp(field.replace(/\./g, '\\.')
          .replace(/\*/g, '.*'), 'g');

      return Object.keys(this.validations).findIndex(item => item.match(regex)) >= 0;
  }

  allMessages(field = '*') {
      const regex = new RegExp(field.replace(/\./g, '\\.')
          .replace(/\*/g, '.*'), 'g');
      const messages = [];

      Object.keys((this.validations)).forEach(key => {
          if(key.match(regex)) {
              messages.push(...this.validations[key]);
          }
      })

      return messages;
  }

  any() {
    return Object.keys(this.validations).length > 0;
  }

  get(field) {
    if (this.has(field)) {
      return this.validations[field];
    }

    return [];
  }

  record(errors) {
    Vue.set(this, 'validations', errors);
  }

  clear(field) {
    if (typeof field === 'undefined' || field === 'all' || field === '') {
      this.validations = {};
      return;
    }

    if (!field.includes('*')) {
      Vue.delete(this.validations, field);
      return;
    }

    const regex = new RegExp(field.replace(/\./g, '\\.')
      .replace(/\*/g, '.*'), 'g');
    Object.keys(this.validations)
      .forEach((key) => {
        if (key.match(regex)) {
          Vue.delete(this.validations, key);
        }
      });
  }
}
