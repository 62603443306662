<template>
<aside class="contact-card">
  <div class="image">
    <img loading="lazy" :src="require(`@/assets/img/${imgFile}`)" :alt="name" width="200" height="200">
  </div>
  <span class="main-info">
        <span class="name" v-text="name"/>
        <span class="job-title" v-text="jobTitle"/>
      </span>
  <span class="contact">
        <span class="phone">Phone: <a :href="`tel:${phone}`" v-text="phone" /></span>
        <span class="mail">Mail: <a :href="`mailto:${mail}`" v-text="mail" /></span>
      </span>
</aside>
</template>

<script>
export default {
  name: 'ContactCard',

  props: {
    imgFile: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    jobTitle: {
      required: true,
      type: String,
    },
    phone: {
      required: true,
      type: String,
    },
    mail: {
      required: true,
      type: String,
    },
  },
}
</script>
