<template>
<div class="value-buttons">
  <svg aria-hidden="true" focusable="false" class="svg -thumbs-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><path d="M36.303 70.702c0-2.5-2.1-4.6-4.6-4.6h-13.2c-4.6 0-8.4-3.8-8.4-8.4 0-4.6 3.8-8.4 8.4-8.4h94.5c3.5 0 4.8.5 5.5 1.2.7.7 1.6 1.8 2.6 3 3.2 3.9 7.2 8.8 12.6 8.8h3.5v55.8c-6.9.2-13.6 3.4-17.8 8.4-.2.3-.4.5-.6.8-.1.1-.2.3-.3.4-12.8 10.1-21.7 21.9-26.3 35-.8 2.3-.7 4.5-.6 6.2 0 .5.1 1 .1 1.5v17.3c-4 3.3-8.9 4-12.8 1.7-6.3-3.7-10.5-16.1-3.5-39.6.4-1.4.1-2.9-.7-4.1-.9-1.2-2.2-1.8-3.7-1.8h-52.4c-4.6 0-8.4-3.8-8.4-8.4 0-4.4 3.4-7.9 7.8-8.3.2 0 .4.1.7.1h13c2.5 0 4.6-2.1 4.6-4.6 0-.6-.1-1.3-.4-1.8-.7-1.7-2.3-3-4.3-3h-13c-.2 0-.4 0-.6.1h-.1c-4.3-.4-7.7-3.9-7.7-8.3 0-4.6 3.8-8.4 8.4-8.4h13.1c2.5 0 4.6-2.1 4.6-4.6 0-2.5-2.1-4.6-4.6-4.6h-1.3c-.2 0-.3-.1-.4-.1h-11.7c-4.4-.2-8-3.8-8-8.3 0-4.6 3.8-8.4 8.4-8.4h13.1c2.5 0 4.5-2.1 4.5-4.6m-29.4 51.9c-3.5 3.2-5.7 7.8-5.7 12.9 0 9.7 7.9 17.6 17.6 17.6h46.4c-7.4 30.5 3.8 41 9.3 44.2 8 4.7 18.1 2.9 25.3-4.4.8-.9 1.3-2 1.3-3.2v-19.3c0-.7 0-1.4-.1-2.1-.1-1.1-.1-2 .1-2.6 4-11.5 11.9-21.9 23.3-30.8 1-.8 1.6-1.6 2-2.2l.3-.4c2.5-3 6.6-4.9 10.8-5.1v1.8c0 2.5 2.1 4.6 4.6 4.6h52c2.5 0 4.6-2.1 4.6-4.6v-62.8c0-2.5-2.1-4.6-4.6-4.6-2.5 0-4.6 2.1-4.6 4.6v58.3h-42.9v-68.7h47.5c2.5 0 4.6-2.1 4.6-4.6 0-2.5-2.1-4.6-4.6-4.6h-52.1c-2.5 0-4.6 2.1-4.6 4.6v1.9h-3.4c-1.3-.2-4.1-3.7-5.5-5.4-1.2-1.4-2.2-2.7-3.3-3.8-3.4-3.3-7.7-3.8-11.9-3.8h-94.5c-9.7 0-17.6 7.9-17.6 17.6 0 5.2 2.3 9.8 5.9 13-3.6 3.2-5.9 7.8-5.9 13s2.3 9.8 5.9 13c-3.6 3.2-5.9 7.8-5.9 13-.1 5.1 2.2 9.7 5.7 12.9m167.3-42.3c3.4 0 6.2-2.8 6.2-6.2 0-3.4-2.8-6.2-6.2-6.2-3.4 0-6.2 2.8-6.2 6.2 0 3.4 2.8 6.2 6.2 6.2" fill="currentColor"/></svg>
  <button type="button" class="button -primary -bordered -small" @click.prevent.stop="e => click(1, e.target)">– –</button>
  <button type="button" class="button -primary -bordered -small" @click.prevent.stop="e => click(2, e.target)">–</button>
  <button type="button" class="button -primary -bordered -small" @click.prevent.stop="e => click(3, e.target)">+</button>
  <button type="button" class="button -primary -bordered -small" @click.prevent.stop="e => click(4, e.target)">+ +</button>
  <svg aria-hidden="true" focusable="false" class="svg -thumbs-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><path d="M163.6 129.3c0 2.5 2.1 4.6 4.6 4.6h13.2c4.6 0 8.4 3.8 8.4 8.4 0 4.6-3.8 8.4-8.4 8.4H86.9c-3.5 0-4.8-.5-5.5-1.2-.7-.7-1.6-1.8-2.6-3-3.2-3.9-7.2-8.8-12.6-8.8h-3.5V81.9c6.9-.2 13.6-3.4 17.8-8.4.2-.3.4-.5.6-.8.1-.1.2-.3.3-.4 12.8-10.1 21.7-21.9 26.3-35 .8-2.3.7-4.5.6-6.2 0-.5-.1-1-.1-1.5V12.3c4-3.3 8.9-4 12.8-1.7 6.3 3.7 10.5 16.1 3.5 39.6-.4 1.4-.1 2.9.7 4.1.9 1.2 2.2 1.8 3.7 1.8h52.4c4.6 0 8.4 3.8 8.4 8.4 0 4.4-3.4 7.9-7.8 8.3-.2 0-.4-.1-.7-.1h-13c-2.5 0-4.6 2.1-4.6 4.6 0 .6.1 1.3.4 1.8.7 1.7 2.3 3 4.3 3h13c.2 0 .4 0 .6-.1h.1c4.3.4 7.7 3.9 7.7 8.3 0 4.6-3.8 8.4-8.4 8.4h-13.1c-2.5 0-4.6 2.1-4.6 4.6 0 2.5 2.1 4.6 4.6 4.6h1.3c.2 0 .3.1.4.1h11.7c4.4.2 8 3.8 8 8.3 0 4.6-3.8 8.4-8.4 8.4h-13.1c-2.5 0-4.5 2.1-4.5 4.6M193 77.4c3.5-3.2 5.7-7.8 5.7-12.9 0-9.7-7.9-17.6-17.6-17.6h-46.4c7.4-30.5-3.8-41-9.3-44.2-8-4.7-18.1-2.9-25.3 4.4-.8.9-1.3 2-1.3 3.2v19.3c0 .7 0 1.4.1 2.1.1 1.1.1 2-.1 2.6-4 11.5-11.9 21.9-23.3 30.8-1 .8-1.6 1.6-2 2.2l-.3.4c-2.5 3-6.6 4.9-10.8 5.1V71c0-2.5-2.1-4.6-4.6-4.6h-52c-2.5 0-4.6 2.1-4.6 4.6v62.8c0 2.5 2.1 4.6 4.6 4.6 2.5 0 4.6-2.1 4.6-4.6V75.5h42.9v68.7H5.8c-2.5 0-4.6 2.1-4.6 4.6 0 2.5 2.1 4.6 4.6 4.6h52.1c2.5 0 4.6-2.1 4.6-4.6v-1.9h3.4c1.3.2 4.1 3.7 5.5 5.4 1.2 1.4 2.2 2.7 3.3 3.8 3.4 3.3 7.7 3.8 11.9 3.8h94.5c9.7 0 17.6-7.9 17.6-17.6 0-5.2-2.3-9.8-5.9-13 3.6-3.2 5.9-7.8 5.9-13s-2.3-9.8-5.9-13c3.6-3.2 5.9-7.8 5.9-13 .1-5.1-2.2-9.7-5.7-12.9M25.7 119.7c-3.4 0-6.2 2.8-6.2 6.2 0 3.4 2.8 6.2 6.2 6.2 3.4 0 6.2-2.8 6.2-6.2 0-3.4-2.8-6.2-6.2-6.2" fill="currentColor"/></svg>
</div>
</template>

<script>
export default {
  name: 'ValueButtons',

  methods: {
    click(value, $target) {
      this.$el.querySelectorAll('.-active').forEach(($button) => {
        $button.classList.remove('-active');
      })
      $target.classList.add('-active');
      this.$emit('selected', value)
    }
  }
}
</script>
