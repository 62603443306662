<template>
<div class="range -sections-4">
  <input class="input" :class="inputClass" type="range" step="1" min="1" max="4" style="width: 100%" value="1" @input.prevent.stop="e => change(parseInt(e.target.value))" />
  <div class="label">
    <span class="left" v-text="left"/>
    <span class="right" v-text="right"/>
  </div>
</div>
</template>

<script>
export default {
  name: 'ValueRange',

  props: {
    color: {
      type: String,
      default: 'blue',
    },
    left: {
      type: String,
      required: true,
    },
    right: {
      type: String,
      required: true,
    },
  },

  computed: {
    inputClass() {
      const classes = [];

      if(this.color) {
        classes.push(`-${this.color}`);
      }

      return classes;
    },
  },

  methods: {
    change(value) {
      this.$emit('selected', value)
    },
  },
}
</script>
