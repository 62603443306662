<template>
<div class="status-quo">
  <div class="category" v-text="`Status Quo | Dimension ${currentIndex}/${count}`"/>
  <h2 class="headline icon-before" :class="[`-${context}`]" v-text="title"/>

  <div class="question" v-for="(question, index) in questions" :key="`actual-state-${context}-${index}`" :style="`transition-delay: ${(index + 1) * 0.25}s`">
    <span class="text" v-html="question" :data-number="`0${index + 1}`"/>
    <ValueButtons @selected="value => selected(index, value)"/>
  </div>

  <div class="navigation" :style="`transition-delay: ${(questions.length + 1) * 0.25}s`">
    <button type="button" class="button -primary -bordered -back" @click.prevent.stop="back">Zurück</button>
    <button type="button" class="button -primary -bordered -next" @click.prevent.stop="next" :disabled="!canContinue">Weiter</button>
  </div>
</div>
</template>

<script>
import IsStep from "@/mixins/IsStep";
import ValueButtons from "@/components/ValueButtons";

export default {
  name: 'StatusQuo',
  mixins: [IsStep],
  components: {ValueButtons},
  props: {
    context: {
      required: true,
      type: String,
    },
    count: {
      required: true,
      type: Number,
    },
    currentIndex: {
      required: true,
      type: Number,
    }
  },

  computed: {
    questions() {
      return this.context ? this.$store.state.actualStates[this.context].questions : [];
    },

    title() {
      return this.context ? this.$store.state.actualStates[this.context].title : '';
    },

    canContinue() {
      return this.context ? this.$store.state.canContinue[this.context] : false;
    },
  },

  methods: {
    selected(index, value) {
      this.$store.commit('actualStateQuestionResult', {context: this.context, index, value});
    }
  }

}
</script>
