<template>
<div class="change-readiness">
  <h3 class="client-name" v-if="clientName" v-text="clientName" />
  <div class="track" :style="style">
    <Start :style="contentStyle" :class="stepClass(0)" @next="next" @back="back"/>
    <Relevance :style="contentStyle" :class="stepClass(1)" @next="next" @back="back"/>
    <IntroductionActualState :style="contentStyle" :class="stepClass(2)" @next="next" @back="back"/>
    <StatusQuo v-for="(state, index) in actualStates"
               :key="`actual-state-${state}`"
               :context="state"
               :style="contentStyle"
               :class="stepClass(3 + index)"
               :count="actualStates.length"
               :current-index="index + 1"
               @next="next"
               @back="back"
    />
    <Result :style="contentStyle" :class="stepClass(3 + actualStates.length)"/>
  </div>
</div>
</template>

<script>
import Start from "./components/steps/Start";
import Relevance from "./components/steps/Relevance";
import StatusQuo from "./components/steps/StatusQuo";
import IntroductionActualState from "./components/steps/IntroductionActualState";
import Result from "./components/steps/Result";

import debounce from "lodash.debounce";
import {mapState} from "vuex";

export default {
  name: 'App',
  components: {Start, Relevance, StatusQuo, IntroductionActualState, Result},

  beforeCreate() {
    this.$store.dispatch('init');
  },

  created() {
    window.addEventListener('resize', debounce(() => {
      this.calcWidth();
    }, 100, {leading: false}));
  },

  mounted() {
    this.calcWidth();
  },

  data() {
    return {
      stepWith: 0,
      topPos: 0,
      style: {},
      contentStyle: {},
      currentPage: 0,
      latestPage: 0,
    };
  },

  computed: {
    actualStates() {
      return this.$store.getters['actualStateKeys']();
    },

    pageCount() {
      // start + relevance + introduction actual states + actual states (is variable) + result
      return this.actualStates.length + 4;
    },

    ...mapState({
      clientName: state => state.clientName,
    }),
  },

  watch: {
    actualStates() {
      this.calcWidth();
    }
  },

  methods: {
    next() {
      this.currentPage++;
      if(this.currentPage > this.latestPage) {
        this.latestPage = this.currentPage;
      }
      // if user is getting to last page trigger result save
      if(this.currentPage === (this.actualStates.length + 3)) {
        this.$store.dispatch('save');
      }
      this.calcCurrentPosition();
    },

    back() {
      this.currentPage--;
      this.calcCurrentPosition();
    },

    calcCurrentPosition() {
      this.$set(this.style, 'transform', `translate3d(${this.stepWith * this.currentPage * -1}px, 0px, 0px)`);
      window.scrollTo({top: this.topPos, left: 0, behavior: 'smooth'});
    },

    calcWidth() {
      const rect = this.$el.getBoundingClientRect();
      const width = rect.width;

      this.$set(this, 'style', {
        width: `${width * (this.pageCount)}px`,
        transform: `translate3d(${width * this.currentPage * -1}px, 0px, 0px)`,
        transition: 'transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s',
      });

      this.$set(this, 'contentStyle', {
        width: `${width}px`,
        flexBasis: `${width}px`,
      });

      this.stepWith = width;
      this.topPos = rect.top;
    },

    stepClass(stepIndex) {
      return {'-animate': stepIndex <= this.latestPage}
    }
  }
}
</script>

<style lang="less">
@import "./less/theme";
</style>
