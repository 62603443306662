<template>
<div class="result">
  <h2 class="headline">Ergebnis</h2>
  <p>Auf Basis der Einschätzungen zu der Relevanz der verschiedenen Dimensionen für ein Unternehmen sowie zum Status Quo werden in diesem Abschnitt nun die Ergebnisse visualisiert. </p>
  <p>Zudem wird ein erster Ausschnitt an Handlungsempfehlungen dargestellt. Für eine umfassende Ergebnisauswertung können Sie sich unten auf der Seite mit ihrer E-Mailadresse eintragen.</p>

  <Chart/>
  <Texts/>

  <div v-if="successMessage" class="alert -success" v-text="successMessage" />
  <form v-else class="form" @submit.prevent.stop="submit">
    <h5 class="headline">Erhalten Sie die vollständige Auswertung</h5>
    <p>Wenn Sie die vollständige Auswertung der Ergebnisse wünschen, hinterlassen Sie uns bitte Ihre E-Mail Adresse:</p>

    <div v-if="errorMessage" class="alert -error" v-text="errorMessage" />

    <div class="input-field -required" :class="{'-error': validations.has('gender')}">
      <label class="label" for="result-gender">Geschlecht</label>
      <select
          class="input -select"
          id="result-gender"
          :disabled="disabled"
          v-model="model.gender"
          @input="validations.clear('gender')"
      >
        <option value=""></option>
        <option value="male">Mann</option>
        <option value="female">Frau</option>
      </select>
      <span
          class="error"
          v-for="(validation, index) in validations.get('gender')"
          :key="index"
          v-text="validation"
      />
    </div>

    <div class="input-field -required" :class="{'-error': validations.has('name')}">
      <input
          type="text"
          class="input -text"
          id="result-name"
          :disabled="disabled"
          v-model="model.name"
          @input="validations.clear('name')"
          placeholder=" "
      />
      <label class="label" for="result-name">Name (Vor- und Nachname)</label>
      <span
          class="error"
          v-for="(validation, index) in validations.get('name')"
          :key="index"
          v-text="validation"
      />
    </div>

    <div class="input-field -required" :class="{'-error': validations.has('email')}">
      <input
          type="email"
          class="input -email"
          id="result-email"
          :disabled="disabled"
          v-model="model.email"
          @input="validations.clear('email')"
          placeholder=" "
      />
      <label class="label" for="result-email">E-Mail</label>
      <span
          class="error"
          v-for="(validation, index) in validations.get('email')"
          :key="index"
          v-text="validation"
      />
    </div>

    <div class="input-field" :class="{'-error': validations.has('annotation')}">
      <label class="label" for="result-annotation">Anmerkung</label>
      <textarea
          class="input -textarea"
          id="result-annotation"
          rows="3"
          :disabled="disabled"
          v-model="model.annotation"
          @input="validations.clear('annotation')"
          placeholder=" "
      />
      <span
          class="error"
          v-for="(validation, index) in validations.get('annotation')"
          :key="index"
          v-text="validation"
      />
    </div>

    <div class="input-field -required" :class="{'-error': validations.has('data_protection')}">
      <input
          type="checkbox"
          class="input -checkbox"
          id="result-data-protection"
          :disabled="disabled"
          :checked="model.data_protection"
          @input="e => inputChecked(e.target.checked, 'data_protection')"
      />
      <label class="text" for="result-data-protection">Ja, ich willige den <a :href="linkDataProtection" target="_blank" rel="noopener noreferrer">Datenschutzrichtlinien</a> ein. Ich möchte die aufgeschlüsselten Ergebnisse meiner Antworten per E-Mail bekommen. Ich bin mit der Verarbeitung meiner Angaben einverstanden. Die Angaben werden für eine Kontaktaufnahme gespeichert. Die Zusage kann jederzeit widerrufen werden.</label>
      <span
          class="error"
          v-for="(validation, index) in validations.get('data_protection')"
          :key="index"
          v-text="validation"
      />
    </div>

    <div class="input-field" :class="{'-error': validations.has('get_in_contact')}">
      <input
          type="checkbox"
          class="input -checkbox"
          id="result-get-in-contact"
          :disabled="disabled"
          :checked="model.get_in_contact"
          @input="e => inputChecked(e.target.checked, 'get_in_contact')"
      />
      <label class="text" for="result-get-in-contact">Ich bin an einer Kontaktaufnahme durch einen Berater interessiert, um die Ergebnisse zu besprechen (optional).</label>
      <span
          class="error"
          v-for="(validation, index) in validations.get('get_in_contact')"
          :key="index"
          v-text="validation"
      />
    </div>

    <input type="submit" class="button -primary" value="Abschicken">
  </form>

  <MailchimpSignupForm v-if="successMessage" :email="model.email" />

  <h3>Ihre Ansprechpartner</h3>
  <p>
    Gerne werden wir unsere Berater über Ihren Kontaktwunsch benachrichtigen.<br>
    Alternativ können Sie diese unter folgenden Angaben selbst kontaktieren:
  </p>
  <div class="contacts">
    <ContactCard
      img-file="dirk_bayas-linke.jpg"
      name="Dirk Bayas-Linke"
      job-title="Principal"
      phone="+49 7024 8056 0"
      mail="dirk.bayas-linke@staufen.ag"
    />
    <ContactCard
        img-file="freda_von_stackelberg.jpg"
        name="Freda von Stackelberg"
        job-title="Senior Consultant"
        phone="+41  44 786 33 11"
        mail="f.stackelberg@staufen-inova.ch"
    />
    <ContactCard
        img-file="ullrich_beck.jpg"
        name="Ullrich Beck"
        job-title="Senior Expert"
        phone="+49 7024 8056 0"
        mail="u.beck@staufen.ag"
    />
  </div>
</div>
</template>

<script>
import Chart from "@/components/Chart";
import Texts from "@/components/Texts";
import Validations from "@/Validations";
import {mapState} from "vuex";
import MailchimpSignupForm from "@/components/MailchimpSignupForm";
import ContactCard from '@/components/ContactCard';

export default {
  name: 'Result',
  components: { ContactCard, MailchimpSignupForm, Chart, Texts},

  data() {
    return {
      disabled: false,
      validations: new Validations(),
      errorMessage: null,
      successMessage: null,
      model: {
        gender: '',
        name: '',
        email: '',
        annotation: '',
        data_protection: false,
        get_in_contact: false,
      },
    };
  },

  computed: {
    ...mapState({
      linkDataProtection: state => state.link.dataProtection,
    }),
  },

  methods: {
    async submit() {
      this.errorMessage = null;
      this.validations.clear();

      try {
        const res = await this.$store.dispatch('mail', this.model);
        if(res && res.message) {
          this.successMessage = res.message;
        }
      } catch (e) {
        if(e.status === 422) {
          this.validations.record(e.response.errors || []);
          return;
        }

        if(e.status === 500 && e.response && e.response.message) {
          this.errorMessage = e.response.message;
          return;
        }

        // TODO handle better
        console.log(e);
      }
    },

    inputChecked(checked, name) {
      this.validations.clear(name);
      this.$set(this.model, name, checked);
    }
  },
}
</script>
