export default {
    methods: {
        next() {
            this.$emit('next');
        },

        back() {
            this.$emit('back');
        },
    }
}
