<template>
<div class="chart">
  <canvas ref="canvas"/>
  <button type="button" class="button -primary" @click.prevent.stop="download">Jetzt herunter laden</button>
</div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  RadarController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    RadarController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
);

Chart.register({
  id: 'custom_canvas_background_color',
  beforeDraw: (chart) => {
    const ctx = chart.canvas.getContext('2d');
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  }
});

export default {
  name: 'Chart',

  data() {
    return {
      chart: null,
    };
  },

  computed: {
    ...mapState({
      results: state => state.results,
    }),
  },

  watch: {
    results: {
      handler() {
        this.update();
      },
      deep: true,
    },
  },

  mounted() {
    this.update();
  },

  methods: {
    update() {
      this.createChart();
    },

    createChart() {
      if (this.chart) {
        this.chart.destroy();
      }

      const rect = this.$refs.canvas.getBoundingClientRect();
      const ctx = this.$refs.canvas.getContext('2d');
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, rect.width, rect.height);
      ctx.restore();

      console.log('create');
      const labels = [];
      const datasetRelevance = {
        label: 'Relevanz des Faktors',
        data: [],
        fill: true,
        backgroundColor: 'transparent',
        borderColor: '#EE7D23',
        pointBackgroundColor: '#EE7D23',
        pointBorderColor: '#EE7D23',
        pointHoverBackgroundColor: '#EE7D23',
        pointHoverBorderColor: '#EE7D23'
      };
      const datasetActualState = {
        label: 'Status Quo',
        data: [],
        fill: true,
        backgroundColor: 'transparent',
        borderColor: '#00b9e4',
        pointBackgroundColor: '#00b9e4',
        pointBorderColor: '#00b9e4',
        pointHoverBackgroundColor: '#00b9e4',
        pointHoverBorderColor: '#00b9e4'
      };

      Object.keys(this.results).forEach((key) => {
        labels.push(this.results[key].label);
        datasetRelevance.data.push(this.results[key].relevance);
        datasetActualState.data.push(this.results[key].actualState / this.results[key].questions.length);
      });

      this.chart = new Chart(this.$refs.canvas, {
        type: 'radar',
        data: {
          labels,
          datasets: [datasetActualState, datasetRelevance]
        },
        options: {
          maintainAspectRatio: false,

          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                padding: 40,
              }
            }
          },
          elements: {
            line: {
              borderWidth: 3,
            },
            point: {
              radius: 5
            }
          },
          scales: {
            r: {
              max: 4,
              min: 0,
              ticks: {
                stepSize: 1,
              },
            }
          }
        },
      });
    },

    download() {
      const tempLink = document.createElement('a');
      tempLink.download = `staufen-change-readiness-check-result.png`;
      tempLink.href = this.chart.toBase64Image('image/png', 1);
      tempLink.style.display = 'none';

      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    }
  }
}
</script>
