<template>
<div class="relevance">
  <div class="category">Relevanz | Abfrage</div>
  <h2 class="headline">Einschätzung der Relevanz der Change Readiness für Ihr Unternehmen</h2>

  <p>Hier können Sie Ihre eigene Benchmark setzen.</p>
  <p>Zum Einstieg in den Change Readiness Check wird zunächst einmal der Blick auf die Elemente der Organisation gerichtet, die für Veränderungsfähigkeit zentral sind. Sie haben die Möglichkeit zu reflektieren und einzuschätzen, inwieweit die verschiedenen Dimensionen für das eigene Unternehmen relevant sind. Auf diese Weise kann die spezifische Verfasstheit der Organisation Rechnung getragen werden.</p>
  <p>Vertrauen Sie bei den Antworten auf Ihre eigene Intuition oder Bauchesstimme.</p>
  <p>Geben Sie hier Ihre Einschätzung ab, inwieweit die folgenden Aspekte für ihr Unternehmen relevant sind.</p>

  <div class="question" v-for="(question, key, index) in relevance" :key="`relevance-${key}`" :style="`transition-delay: ${(index + 1) * 0.25}s`">
    <h3 class="headline icon-before" :class="[`-${key}`]" v-text="question.title"/>
    <span class="text" v-html="question.text"/>
    <ValueRange @selected="value => selected(key, value)" color="orange" left="überhaupt nicht relevant" right="äußerst relevant"/>
  </div>

  <div class="navigation" :style="`transition-delay: ${(Object.keys(relevance).length + 1) * 0.25}s`">
    <button type="button" class="button -bordered -primary -next" @click.prevent.stop="next" :disabled="!canContinue">Weiter</button>
  </div>
</div>
</template>

<script>
import {mapState} from 'vuex';
import ValueRange from "../ValueRange";
import IsStep from "@/mixins/IsStep";

export default {
  name: 'Relevance',
  mixins: [IsStep],
  components: {ValueRange},
  computed: {
    canContinue() {
      return this.$store.getters['canContinueRelevance']();
    },

    ...mapState({
      relevance: state => state.relevance,
    }),
  },
  methods: {
    selected(key, value) {
      this.$store.commit('relevanceResult', {key, value});
    }
  }
}
</script>
