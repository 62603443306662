import Vue from 'vue';
import App from './App.vue';
import store from './store';

Vue.config.productionTip = false;

const $el = document.querySelector('[data-vue="change-readiness"]');
if($el) {
  store.commit('changeLinkDataProtection', $el.getAttribute('data-dataprotection'))

  new Vue({
    store,
    render: h => h(App),
  }).$mount($el);
}
