<template>
<div class="introduction">
  <div class="category">Status Quo | Einleitung</div>
  <h2 class="headline">Einschätzung des Status Quo</h2>

  <p>Nachdem im vorherigen Schritt die Relevanz der verschiedenen Dimensionen für das Unternehmen eingeschätzt wurde, soll nun der Ist-Zustand der einzelnen Elemente in der Organisation ermittelt werden.</p>
  <p>Beantworten Sie hierzu bitte intuitiv pro Dimension die fünf Fragen in der 4er Skala.</p>
  <ul>
    <li>Ich stimme überhaupt nicht zu (– –)</li>
    <li>Ich stimme nicht zu (–)</li>
    <li>Ich stimme zu (+)</li>
    <li>Ich stimme voll und ganz zu (+ +)</li>
  </ul>

  <div class="navigation">
    <button type="button" class="button -primary -bordered -back" @click.prevent.stop="back">Zurück</button>
    <button type="button" class="button -primary -bordered -next" @click.prevent.stop="next">Weiter</button>
  </div>
</div>
</template>

<script>
import IsStep from "@/mixins/IsStep";

export default {
  name: 'IntroductionActualState',
  mixins: [IsStep],
}
</script>
