<template>
<div class="accordion">
  <div class="item" v-for="(res, key) in results" :key="`result-accordion-${key}`">
    <input type="checkbox" :id="`result-accordion-${key}`" />
    <label :for="`result-accordion-${key}`" class="label">
      <span class="icon-before" :class="`-${key}`" v-text="res.title"/>
    </label>
    <div class="content" v-text="res.text"/>
  </div>
</div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'Texts',
  computed: {
    ...mapState({
      results: state => state.results,
    }),
  },
}
</script>
