<template>
<form action="https://staufen.us13.list-manage.com/subscribe/post?u=34b4ed05a7d8fcf3c8de6c845&amp;id=f066784c83" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="form" target="_blank" novalidate>
  <h3>Erlaubnis zum Marketing</h3>
  <p>Die STAUFEN.AG wird Ihre Informationen dazu verwenden, um Ihnen regelmäßig aktuelle Informationen zu senden.</p>

  <div class="input-field -required">
    <input
        type="email"
        class="input -email -required"
        id="mce-EMAIL"
        name="EMAIL"
        :value="email"
        placeholder=" "
    />
    <label class="label" for="mce-EMAIL">E-Mail</label>
  </div>
  <div class="input-field -required">
    <input type="checkbox" class="input -checkbox" id="gdpr_3965" name="gdpr[3965]" value="Y" />
    <label class="text" for="gdpr_3965">Ja, ich möchte den E-Mail Newsletter erhalten</label>
  </div>
  <p>Sie können Ihre Meinung jederzeit ändern, indem Sie auf den Abmelde-Link klicken, den Sie in der Fußzeile jeder E-Mail, die Sie von uns erhalten, finden können, oder indem Sie uns unter news@staufen.ag kontaktieren. Wir werden Ihre Informationen mit Sorgfalt und Respekt behandeln. Weitere Informationen zu unseren Datenschutzpraktiken finden Sie auf unserer Website. Indem Sie unten klicken, erklären Sie sich damit einverstanden, dass wir Ihre Informationen in Übereinstimmung mit diesen Bedingungen verarbeiten dürfen.</p>
  <p>We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing. <a href="https://mailchimp.com/legal/" target="_blank">Learn more about Mailchimp's privacy practices here.</a></p>

  <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_34b4ed05a7d8fcf3c8de6c845_f066784c83" tabindex="-1" value=""></div>

  <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button">
</form>
</template>

<script>
export default {
  name: 'MailchimpSignupForm',

  props: {
    email: {
      default: '',
      type: String,
    },
  },
}
</script>
